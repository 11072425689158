@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');
:root {
  --background: linear-gradient(90deg,#0c71ca,#64bce2);
  --background-webkit: -webkit-gradient(linear,left top,right top,from(#0c71ca),to(#64bce2));
  --block-color: white;
  --text-color: #111;
  --rasp-separator: #e0e0e0;
  --border-block: none

}

@media (prefers-color-scheme: dark) {
  :root {
    --background: linear-gradient(90deg,#202020,#272727);
    --background-webkit: -webkit-gradient(linear,left top,right top,from(#202020),to(#272727));
    --block-color: rgb(0, 0, 0);
    --text-color: #cccccc;
    --rasp-separator: #525252;
    --border-block: 1px solid #272727
  }
}

html body {
  color: var(--text-color);
  line-height: 1.25;
  background-image: var(--background-webkit);
  background-image: var(--background);
  margin-bottom:40px
}

.refresh{
  display: flex;
  width: 50px;
  height: 50px;
  background-color: var(--block-color);
  border-radius: 50% 0 0 50%;
  text-align: center;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  margin: 10px;
  box-shadow: 0 0 10px 2px rgba(34,60,80,.2);
  position: absolute;
  top: 30px;
  right: -10px;
  border: var(--border-block);
  border-right: none;
}

.refresh_icon{
  width: 25px;
  height: 25px;

}

.replaced {
  display: inline-block;
  font-size: 10px;
  vertical-align: middle;
  padding: 2px 5px;
  background-color: #ffcbcb;
  margin-right: 1ch;
  border-radius: 4px;
  color: #111;
}

.replaced.big{
  font-size: 14px;
}

*{
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.block {
  background-color: var(--block-color);
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  width:100%;
}

.headBlock {
  background-color: var(--block-color);
  display: inline-block;
  padding: 40px;
  margin: 40px;
  border-radius: 16px;
  width: calc(100% - 160px);
}


.eventImage {
  display: inline-block;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
}

.eventImage.small {
  width: 100px;
  height: 100px;
}



.eventImage img{
  width: 100%;
}

.dayEmpty{
  margin: 30px;
  font-size: 18px;
}

.cabName {
  font-weight: 600;
  font-size: 40px;
}

.headBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeClock {
  font-size: 40px;
  text-align: right;
}



.blockHead {
  font-size: 30px;
  font-weight: 600;
  padding: 30px 30px 10px 30px;
}

.blockContent{
  padding: 0 30px 30px 30px;
}

.teacher {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.eventTime {
  margin: 10px 0px;
}

.eventClass {
  margin: 10px 0px;
}

.eventTeacher {
  margin: 0 0 0 20px;
}

.str{
  font-size: 25px;
}


.time {
  font-weight: 600;
}

.row {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  border-bottom: 1px solid var(--rasp-separator);
}

.row div {
  margin: 5px;
}

span.free {
  font-size: 30px;
  font-weight: 600;
}

.select-search-container {
  margin: 20px 0;
}

.qrdiv{
  display: none;
}

.currentTime {
  display: none;
}

.bottom{
  display: none;
}

.bottomBlock {
  margin: 10px;
  position: fixed;
  bottom: 0px;
  width: calc(100% - 60px);
  background-color: var(--block-color);
  display: inline-block;
  padding: 20px;
  border-radius: 16px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: var(--text-color);
}

.bottomBlock a {
  text-decoration: none;
  color: var(--text-color);
}

.weekBlock {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--block-color);
  padding: 5px 10px;
  margin: 40px;
  margin-top: 0px;
  border-radius: 16px;
  width: calc(100% - 100px);
}

.week_day {
  padding: 5px;
  margin: 5px 5px;
  width: 100%;
  list-style-type: none;
  text-align: center;
  cursor: pointer;
}

.week_day:focus-visible{
  outline: none;
}

.week_day.react-tabs__tab--selected {
  border-bottom: 2px solid #0c70c9;
  font-weight: 700;
}

.block.rasp {
  margin: 10px;
}

@media screen and (max-width: 1000px) {
  .grid{
    display: grid;
    grid-template-columns: 1fr ;
    margin: 0px 10px;
    gap: 10px;
  }
  .headBlock {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px;
    width: calc(100% - 100px);
  }

  .weekBlock {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 10px;
    margin-top: 0px;
    width: calc(100% - 40px);
  }

  .cabName {
    font-weight: 600;
    font-size: 30px;
  }
  .timeClock {
    font-size: 30px;
    text-align: right;
  }
  .blockHead {
    font-size: 25px;
    font-weight: 600;
    padding: 30px 30px 10px 30px;
  } 
  .str{
    font-size: 20px;
  }

  .block {
    max-width:calc(100vw - 20px);
  }

  .bottom{
    display: inline-block;
    height: 30px;
    width: 100%;

  }
  .blockContent{
    padding: 0 20px 20px 20px;
  }

}

@media screen and (min-width: 1001px) {
  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0px 40px;
    gap: 40px;
  }

  .headBlock > div {
    min-width: 30%;
  }
  .currentTime {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

}

@media screen and (width: 1280px) {
  .block{
    max-height: calc(100vh - 270px);
    overflow: hidden;
  }
  .qr {
    height: 50px;
    float: right;
    transform: scale(2.5);
    padding-right: 10px;
  } 
  .qrdiv{
    display: block;
  }
  .currentTime {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}




.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #687796;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Inter', sans-serif;

  width: 300px;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
      --select-search-font: 'Montserrat', sans-serif;
  }
}

@media (prefers-color-scheme: dark) {
  .select-search-container {
      --select-search-background: #000;
      --select-search-border: #313244;
      --select-search-selected: #4f5060;
      --select-search-text: #fff;
      --select-search-subtle-text: #a6adc8;
      --select-search-highlight: #1e1e2e;
  }
}

body.is-dark-mode .select-search-container {
  --select-search-background: #000;
  --select-search-border: #313244;
  --select-search-selected: #4f5060;
  --select-search-text: #fff;
  --select-search-subtle-text: #a6adc8;
  --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #687796;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  border-radius: 3px;
  outline: none;
  font-family: var(--select-search-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-select {
  background: var(--select-search-background);
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-search-border);
  overflow: auto;
  max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-radius: 3px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-search-background);
  border: none;
  outline: none;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--select-search-border);
  color: var(--select-search-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}
